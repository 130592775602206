import c from "classnames";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ConfigContext from "../../../../context/ConfigContext";
import UserContext from "../../../../context/UserContext";
import { Label, SMALL, TINY } from "@arborknot/design-system-v2";
import { User } from "../../../../types/User";
import styles from "./outstanding.module.scss";
import { formatAmountWithCurrency, toPrice } from "src/utils/formatAmountWithCurrency";

interface OutstandingProps {
  className?: string;
  isSummary?: boolean;
}

function Outstanding({ className, isSummary }: OutstandingProps) {
  const { user } = useContext(UserContext);
  const { outstandingBalance = 0 } = user as User;
  const { t } = useTranslation();
  const { currencyCode, dcaCountryCode } = useContext(ConfigContext);

  return (
    <div className={c(className, styles.outstandingContainer)}>
      <Label size={TINY} isBold={true} className={"ash-color"}>
        {t("navbar-outstanding-balance")}
      </Label>
      <Label
        size={SMALL}
        isBold={true}
        className={styles.outstandingLabel}
        data-is-summary={isSummary}
      >
        {formatAmountWithCurrency(toPrice(outstandingBalance, dcaCountryCode), currencyCode)}
      </Label>
    </div>
  );
}

export default Outstanding;
