export const routes = {
  DEFAULT: "/",
  POI: "/poi",
  LANDING: "/deals",
  PAYMENT_METHODS: "/paymentMethods",
  CHANGE_PAYMENT_METHOD: "/changePaymentMethod",
  CREDIT_CARD: "/creditCard",
  BANK_TRANSFER: "/bankTransfer",
  APPLE_PAY: "/applePay",
  GOOGLE_PAY: "/googlePay",
  BPAY: "/bpay",
  CONGRATS: "/congratulations",
  CONGRARTS_IFRAME: "/congratulationsIframe",
  SUMMARY: "/summary",
  DEADEND: "/deadend",
  ACTIONS: "/actions",
  ACTIONS_SUCCESS: "/actions/success",
  PAYME_CARD: "/paymecard",
  PAYME_BANK_TRANSFER: "/paymebanktransfer",
  NUVEI_ACH: "/nuveiach",
  NUVEI_CARD: "/nuveicard",
  SUBSCRIPTION: "/subscription",
  DISCLOSURE: "/disclosure",
};

export const routesWithDebtdId = {
  POI: "/poi/:runDebtId",
  POI_BLOCKED: "/poi/:runDebtId/blocked",
  LANDING: "/deals/:runDebtId",
  PAYMENT_METHODS: "/paymentMethods/:runDebtId",
  CHANGE_PAYMENT_METHOD: "/changePaymentMethod/:runDebtId",
  CREDIT_CARD: "/creditCard/:runDebtId",
  BANK_TRANSFER: "/bankTransfer/:runDebtId",
  APPLE_PAY: "/applePay/:runDebtId",
  GOOGLE_PAY: "/googlePay/:runDebtId",
  BPAY: "/bpay/:runDebtId",
  CONGRATS: "/congratulations/:runDebtId",
  CONGRARTS_IFRAME: "/congratulationsIframe/:runDebtId",
  SUMMARY: "/summary/:runDebtId",
  DEADEND: "/deals/:runDebtId",
  ACTIONS: "/actions/:runDebtId",
  ACTIONS_SUCCESS: "/actions/success/:runDebtId",
  PAYME_CARD: "/paymecard/:runDebtId",
  PAYME_BANK_TRANSFER: "/paymeBankTransfer/:runDebtId",
  NUVEI_ACH: "/nuveiach/:runDebtId",
  NUVEI_CARD: "/nuveicard/:runDebtId",
  SUBSCRIPTION: "/subscription/:runDebtId/:optType",
  DISCLOSURE: "/disclosure/:dcaId",
};
