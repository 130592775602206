import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./CookiesConsent.module.scss";
import { useTranslation } from "react-i18next";
import { Button, Label, MEDIUM } from "@arborknot/design-system-v2";
import { useLocation } from "react-router-dom";
import ConfigContext from "src/context/ConfigContext";
import { recordCustomerConsent } from "src/api/requests";
import { ConsentStatus } from "src/types/IConsent";

const CookieConsent: React.FC = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const location = useLocation();
  const portalConfig = useContext(ConfigContext);
  const ttl = 31536000;

  const customerId = useMemo(() => {
    return portalConfig.customerId;
  }, [portalConfig]);

  const getCookieConsent = () => {
    const itemStr = localStorage.getItem("cookieConsent");
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.cookieDate) {
      localStorage.removeItem("cookieConsent");
      return null;
    }
    return item;
  };

  const isCookieDateValid = useMemo(() => {
    if (typeof window === "undefined") {
      return false;
    }

    const cookieConsent = getCookieConsent();

    if (cookieConsent?.cookieDate && cookieConsent?.customerId === customerId) {
      return Date.now() - +cookieConsent.cookieDate < 1000 * 60 * 60 * 24 * 365;
    }
    return false;
  }, [customerId]);

  useEffect(() => {
    setShow(!isCookieDateValid);
  }, [isCookieDateValid]);

  const updateCookieConsent = async (consent: ConsentStatus) => {
    if (customerId)
      await recordCustomerConsent({
        customerId,
        status: consent,
        page: location.pathname,
        userAgent: window.navigator.userAgent,
      }).then(() => setShow(false));
  };

  const onAccept = async () => {
    const now = new Date();
    const cookieConsent = {
      cookieDate: now.getTime() + ttl,
      hasAcceptedCookies: true,
      customerId: customerId,
    };
    localStorage.setItem("cookieConsent", JSON.stringify(cookieConsent));
    await updateCookieConsent(ConsentStatus.accepted);
  };

  const onReject = () => {
    const now = new Date();
    const cookieConsent = {
      cookieDate: now.getTime() + ttl,
      hasAcceptedCookies: false,
      customerId: customerId,
    };
    localStorage.setItem("cookieConsent", JSON.stringify(cookieConsent));
    updateCookieConsent(ConsentStatus.rejected);
  };

  if (show) {
    return (
      <div className={styles.container}>
        <Label className={styles.label} size={MEDIUM}>
          {t("cookieConsentText")}&nbsp;
          <a
            href={"https://arborknot.com/privacy-policy/#cookies"}
            target={"_blank"}
            rel="noreferrer"
            data-testid={"cookie-consent-link"}
          >
            <Label size={MEDIUM} className={styles.link}>
              {t("cookieConsentLink")}
            </Label>
          </a>
        </Label>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            size={MEDIUM}
            controlType="textOnly"
            type={"secondary"}
            onPress={onReject}
            text={t("cookieConsentReject")}
            dataTestId={"cookieReject"}
          />
          <Button
            controlType="textOnly"
            className={styles.button}
            size={MEDIUM}
            type={"primary"}
            onPress={onAccept}
            text={t("cookieConsentAccept")}
            dataTestId={"cookieAccept"}
          />
        </div>
      </div>
    );
  }

  return <></>;
};

export default CookieConsent;
