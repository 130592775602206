import { SpinnerIcon } from "@arborknot/design-system-v2";
import React from "react";
import c from "classnames";
import styles from "./LoadingSpinner.module.scss";

export type LoadingSpinnerProps = {
  className?: string;
};

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  className,
}) => {
  return <SpinnerIcon className={c(className, styles.container)} />;
};
