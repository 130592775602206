import { useEffect, useState } from "react";

export const useWebChat = (disableWebchat: boolean) => {
    const [isShowWebChat, setIsShowWebChat] = useState(false);
  
    useEffect(() => {
      setIsShowWebChat(false);
      if(!disableWebchat) {
        setTimeout(() => {
          setIsShowWebChat(true);
        }, 3000)
      }
    }, [disableWebchat])
  
    return isShowWebChat;
  }