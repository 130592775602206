import React from "react";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import DynamicContentContextProvider from "src/context/DynamicContentContextProvider";
import PageLayout from "../components/pageLayout/PageLayout";
import { routes, routesWithDebtdId } from "../types/routes";
import AuthContextProvider from "src/context/AuthContextProvider";

const Poi = React.lazy(() => import("./poi/poi"));
const Blocked = React.lazy(() => import("./blocked/blocked"));
const Landing = React.lazy(() => import("./landing/landing"));
const Actions = React.lazy(() => import("src/pages/actions/actions"));
const ActionsSuccess = React.lazy(
  () => import("src/pages/actionsSuccess/actionsSuccess")
);
const PaymentMethods = React.lazy(
  () => import("./paymentMethods/paymentMethods")
);
const CreditCard = React.lazy(() => import("./CreditCard/creditCard"));
const BankTransfer = React.lazy(() => import("./bankTransfer/bankTransfer"));
const ApplePay = React.lazy(() => import("./applePay/applePay"));
const GooglePay = React.lazy(() => import("./googlePay/googlePay"));
const Congrats = React.lazy(() => import("./congrats/congrats"));
const Summary = React.lazy(() => import("./summary/summary"));
const PaymeCard = React.lazy(() => import("./paymeCard/paymeCard"));
const PaymeBankTransfer = React.lazy(
  () => import("src/pages/paymeBankTransfer/paymeBankTransfer")
);
const NuveiACH = React.lazy(() => import("./nuveiACH/nuveiACH"));
const NuveiCard = React.lazy(() => import("./nuveiCard/nuveiCard"));
const Subscription = React.lazy(
  () => import("src/pages/subscription/subscription")
);
const SubscriptionLayout = React.lazy(
  () => import("src/pages/subscription/subscriptionLayout")
);
const Disclosure = React.lazy(() => import("src/pages/disclosure/disclosure"))

function Router() {
  const RoutesComponent = () =>
    useRoutes([
      {
        path: routesWithDebtdId.POI,
        element: (
          <PageLayout navbar={false} withLogo={false}>
            <Poi />
          </PageLayout>
        ),
      },
      {
        path: routes.DEFAULT,
        element: <Navigate to={routes.POI} />,
      },
      {
        path: routes.POI,
        element: (
          <PageLayout navbar={false} withLogo={false} withConsent={false}>
            <Poi />
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.POI_BLOCKED,
        element: (
          <PageLayout navbar={false} withLogo={false}>
            <Blocked />
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.LANDING,
        element: (
          <PageLayout withLogo={false}>
            <DynamicContentContextProvider>
              <Landing />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.ACTIONS,
        element: (
          <PageLayout withLogo={false}>
            <DynamicContentContextProvider>
              <Actions />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.ACTIONS_SUCCESS,
        element: (
          <PageLayout withLogo={false}>
            <ActionsSuccess />
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.PAYMENT_METHODS,
        element: (
          <PageLayout>
            <DynamicContentContextProvider>
              <PaymentMethods />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.CHANGE_PAYMENT_METHOD,
        element: (
          <PageLayout>
            <DynamicContentContextProvider>
              <AuthContextProvider>
                <PaymentMethods />
              </AuthContextProvider>
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.CREDIT_CARD,
        element: (
          <PageLayout>
            <DynamicContentContextProvider>
              <CreditCard />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.BANK_TRANSFER,
        element: (
          <PageLayout>
            <DynamicContentContextProvider>
              <BankTransfer />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.APPLE_PAY,
        element: (
          <PageLayout>
            <ApplePay />
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.GOOGLE_PAY,
        element: (
          <PageLayout>
            <GooglePay />
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.CONGRATS,
        element: (
          <PageLayout withLogo={false}>
            <DynamicContentContextProvider>
              <Congrats />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.CONGRARTS_IFRAME,
        element: (
          <PageLayout withLogo={false} navbar={false}>
            <DynamicContentContextProvider>
              <Congrats />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.SUMMARY,
        element: (
          <PageLayout>
            <DynamicContentContextProvider>
              <Summary />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.PAYME_CARD,
        element: (
          <PageLayout>
            <DynamicContentContextProvider>
              <PaymeCard />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.PAYME_BANK_TRANSFER,
        element: (
          <PageLayout>
            <DynamicContentContextProvider>
              <PaymeBankTransfer />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.NUVEI_CARD,
        element: (
          <PageLayout>
            <DynamicContentContextProvider>
              <NuveiCard />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.NUVEI_ACH,
        element: (
          <PageLayout>
            <DynamicContentContextProvider>
              <NuveiACH />
            </DynamicContentContextProvider>
          </PageLayout>
        ),
      },
      {
        path: routesWithDebtdId.SUBSCRIPTION,
        element: (
          <SubscriptionLayout>
            <Subscription />
          </SubscriptionLayout>
        ),
      },
      {
        path: routesWithDebtdId.DISCLOSURE,
        element: (
          <PageLayout withLogo={false} navbar={false}>
            <Disclosure />
          </PageLayout>
        ),
      },

      {
        path: "*",
        element: (
          <pre>
            404
            <br />
            <br />
            error reason:
            <br />
            {window.failureReason}
          </pre>
        ),
      },
    ]);

  return (
    <>
      <BrowserRouter>
        <RoutesComponent />
      </BrowserRouter>
    </>
  );
}

export default Router;
