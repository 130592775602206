import {
  Iso27001Icon,
  Label,
  PciIcon,
  TINY,
} from "@arborknot/design-system-v2";
import c from "classnames";
import { Suspense, lazy, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { prefixUrlWithHttpIfMissing } from "src/utils/utils";
import ConfigContext from "../../context/ConfigContext";
import styles from "./footer.module.scss";
import { LanguageSelector } from "./languageSelector/LanguageSelector";
import { useWebChat } from "src/hooks/useWebChat";
import classNames from "classnames";

const WebChat = lazy(() => import("../webchat/webChat"));

type FooterProps = {
  className?: string;
  disableWebchat?: boolean;
};
const Footer: React.FC<FooterProps> = ({
  className,
  disableWebchat = true,
}) => {
  const isShowWebChat = useWebChat(disableWebchat);
  const { dcaName, dcaLegalUrl, dcaPhone, dcaAddress } =
    useContext(ConfigContext);
  const { t } = useTranslation();

  let legalUrl = prefixUrlWithHttpIfMissing(dcaLegalUrl ?? "");

  const renderLabel = (children?: string | JSX.Element, isSeparator: boolean = true) => {
    return (
      children && (
        <>
          <Label size={TINY} className={styles.legalText}>
            {children}
          </Label>
          {isSeparator && '|'}
        </>
      )
    );
  };

  return useMemo(
    () => (
      <div className={c(styles.footer, className)}>
        <div className={styles.wrapperContainer}>
          <div className={styles.container}>
            {renderLabel(t('footer-closure'), false)}
          </div>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              {renderLabel(dcaName)}
              {renderLabel(dcaAddress)}
              {renderLabel(t('footer-phone-prefix', { number: dcaPhone }))}
              {renderLabel(
                <a href={legalUrl} data-testid={"footer-legel-link"}>
                  {t("footer-legal")}
                </a>
                , false)}
            </div>
            <div className={classNames(styles.container, styles.containerIcons)}>
              <LanguageSelector />
              <div className={styles.icon}>
                <PciIcon />
              </div>
              <div className={styles.icon}>
                <Iso27001Icon />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.webchat}>
            {isShowWebChat && (
              <Suspense>
                <WebChat />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    ),
    [dcaName, dcaLegalUrl, isShowWebChat]
  );
};

export default Footer;
