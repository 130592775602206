import { useCallback, useContext } from "react";
import ConfigContext from "src/context/ConfigContext";
import { useParams } from "react-router-dom";
import UserContext from "src/context/UserContext";
import { sendEvent } from "src/api/requests";
import { getDeviceName, getOperatingSystem } from "src/utils/mobileDetect";
import { EventPortal } from "@arborknot/event_types";

const useEvent = () => {
  const { runDebtId } = useParams();
  const { dcaId, channelId, debtId } = useContext(ConfigContext);
  const { plan, deals } = useContext(UserContext);
  const sendEventCallback = useCallback(
    (
      eventId: EventPortal,
      params?: {
        fromLang?: string;
        toLang?: string;
        incentivizedActionId?: string;
        targetElement?: string;
      }
    ) => {
      if (
        debtId &&
        dcaId &&
        runDebtId &&
        getDeviceName() &&
        getOperatingSystem() &&
        eventId
      ) {
        sendEvent({
          fromLang: params?.fromLang,
          toLang: params?.toLang,
          campaignRunDebtId: runDebtId,
          channelId,
          dcaId,
          debtId,
          device: getDeviceName(),
          eventId,
          offerGroupId: deals?.[0]?.offerGroupId || plan?.deal?.offerGroupId,
          operatingSystem: getOperatingSystem(),
          incentivizedActionId: params?.incentivizedActionId,
          targetElement: params?.targetElement
        }).then();
      } else {
        console.warn("Missing required fields for event", {
          debtId,
          dcaId,
          runDebtId,
          device: getDeviceName(),
          eventId,
          operatingSystem: getOperatingSystem(),
        });
      }
    },
    [channelId, dcaId, debtId, deals, plan, runDebtId]
  );

  return { sendEventCallback };
};

export default useEvent;
