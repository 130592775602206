import { useEffect } from "react";
import * as FullStory from "@fullstory/browser";

export const useFullstoryIdentify = (debtId?: string) => {
  useEffect(() => {
    if (debtId) {
      const FULL_STORY_ORGANIZATION_ID =
        window.__RUNTIME_CONFIG__.REACT_APP_FULL_STORY_ORGANIZATION_ID;

      if (FULL_STORY_ORGANIZATION_ID) {
        FullStory.identify(debtId);
      }
    }
  }, [debtId]);
};
