import { useContext, useMemo } from "react";
import UserContext from "../../../../context/UserContext";
import SideMenuHeader from "./Components/SideMenuHeader/SideMenuHeader";
import SideMenuRow from "./Components/SideMenuRow/SideMenuRow";
import styles from "./sideMenu.module.scss";
import { useTranslation } from "react-i18next";
import { User } from "../../../../types/User";
import ConfigContext from "../../../../context/ConfigContext";
import { formatAmountWithCurrency, toPrice } from "src/utils/formatAmountWithCurrency";
import { BannerIA } from "src/components/banner/BannerIA";

interface SideMenuProps {
  onCloseClick: () => void;
}

function SideMenu({ onCloseClick }: SideMenuProps) {
  const { user } = useContext(UserContext);
  const { firstName, lastName, outstandingBalance = 0, debt } = user as User;
  const { t, i18n } = useTranslation();

  const { currencyCode, dcaCountryCode } = useContext(ConfigContext);

  const rows = useMemo(
    () => [
      { label: t("sidemenu-firstname"), value: firstName },
      { label: t("sidemenu-lastname"), value: lastName },
      {
        label: t("sidemenu-outstandingbalance"),
        value: `${formatAmountWithCurrency(
          toPrice(outstandingBalance, dcaCountryCode),
          currencyCode
        )}`,
        valueClassName: "rose-color label-bold",
        expandableRows: [
          {
            label: t("sidemenu-penaltiesfee"),
            value: `${formatAmountWithCurrency(
              debt?.penaltiesFee?.toLocaleString(),
              currencyCode
            )}`,
            valueClassName: styles.lineThroughText,
            isHide: !debt?.isShowPenalties,
          },
        ],
      },
      { label: t("sidemenu-accountnumber"), value: debt?.accountNumber },
      { label: t("sidemenu-referencenumber"), value: debt?.extId },
      { label: t("sidemenu-original-creditor"), value: debt?.originalCreditor },
      { label: t("sidemenu-original-account"), value: debt?.referenceNumber },
      { label: t("sidemenu-current-owner"), value: debt?.masterClient || debt?.clientName },
      { label: t("sidemenu-retail-vendor"), value: debt?.retailVendor },

    ],
    [
      firstName,
      lastName,
      outstandingBalance,
      currencyCode,
      debt?.accountNumber,
      debt?.externalClientId,
      i18n.language,
    ]
  );

  const rowComponents = useMemo(
    () =>
      rows.map(
        ({ label, value, valueClassName, expandableRows }) =>
          <SideMenuRow
            key={label}
            label={label}
            value={value}
            valueClassName={valueClassName}
            expandableRows={expandableRows}
          />
      ),
    [rows]
  );

  return (
    <div className={styles.container}>
      <div className={styles.sideMenuContainer}>
        <SideMenuHeader onCloseClick={onCloseClick} />
        {rowComponents}
      </div>
      <div className={styles.bannerWrap}>
        <BannerIA target="menu_banner"/>
      </div>
    </div>
  );
}

export default SideMenu;
