import { Button, CaretDownIcon, CaretUpIcon, Label, SMALL } from "@arborknot/design-system-v2";
import { useMemo, useState } from "react";
import styles from "./sideMenuRow.module.scss";
import c from "classnames";

interface SideMenuRowProps {
  label?: string;
  value?: string;
  labelClassName?: string;
  valueClassName?: string;
  expandableRows?: ExpandableSideMenuRowProps[];
}

type ExpandableSideMenuRowProps = SideMenuRowProps & {
  isHide?: boolean;
};

function SideMenuRow({
  label,
  value,
  labelClassName,
  valueClassName,
  expandableRows
}: SideMenuRowProps) {
  const [isExpand, setIsExpand] = useState(false);
  const filteredExpandableRows = useMemo(() => {
    return expandableRows?.filter(expandableRow => !expandableRow.isHide);
  }, [expandableRows]);

  return (
    <>
      <Label size={SMALL} isBold={true} className={labelClassName}>
        {label}:
        {filteredExpandableRows && filteredExpandableRows?.length > 0 && (
          <div className={styles.expandButtonContainer}>
            <Button
              onPress={() => setIsExpand(!isExpand)}
              type="ghost"
              controlType="iconOnly"
              size={"tiny"}
              IconComponent={isExpand ? <CaretUpIcon /> : <CaretDownIcon />}
              dataTestId={`expendable`}
            />
          </div>
        )}
      </Label>
      <div>
        <Label size={SMALL} className={valueClassName}>
          {value}
        </Label>
      </div>
      {isExpand &&
        filteredExpandableRows?.map(expandableRow => (
          <>
            <Label size={SMALL} isBold={true} className={c(styles.expandableRow, expandableRow.labelClassName)}>
              <span className={styles.expandableLabel}>{expandableRow.label}:</span>
            </Label>
            <div className={styles.expandableRow}>
              <Label size={SMALL} className={c(styles.expandableRow, expandableRow.valueClassName)}>
                {expandableRow.value}
              </Label>
            </div>
          </>
        ))
      }
    </>
  );
}
export default SideMenuRow;
