import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserContext from "src/context/UserContext";
import { LocationStateType } from "src/types/location";
import { routes } from "src/types/routes";

type PageLayoutProps = {
  children: any;
};

const NavigationGuard: React.FC<PageLayoutProps> = ({ children }) => {
  const { runDebtId } = useParams();
  const { user, canPay } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as LocationStateType;
  const isChangePayment = locationState?.changePaymentMethod === true;
  const isPaid = user?.isPaid;
  const strippedPath = `/${location.pathname.split("/")[1]}`;

  if (
    (isPaid || !canPay) &&
    !isChangePayment &&
    [
      routes.PAYMENT_METHODS,
      routes.APPLE_PAY,
      routes.GOOGLE_PAY,
      routes.CREDIT_CARD,
      routes.BANK_TRANSFER,
    ].includes(strippedPath)
  ) {
    window.failureReason = NavigationGuard.name;
    navigate(`${routes.SUMMARY}/${runDebtId}`);
  }

  return <>{children}</>;
};

export default NavigationGuard;
