import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import AuthContext from './AuthContext';
import { authValidate, getSummaryData } from "src/api/requests";
import { getAfterLoginRoute } from "src/utils/routes";
import UserContext from "./UserContext";
import { SummaryData } from "src/types/SummaryData";
import { routes } from "src/types/routes";

const AuthContextProvider = ({
  children,
  withSummary = true, 
}: {
  children: JSX.Element;
  withSummary?: boolean;
}): JSX.Element => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const { runDebtId } = useParams();
  const location = useLocation();
  const { setUser, setPlan } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    authValidate()
    .then(() => setIsAuth(true))
    .catch(() => setIsAuth(false))
  }, [location])

  useEffect(() => {
    if(withSummary && runDebtId && isAuth) {
      getSummaryData(runDebtId)
      .then((res: SummaryData | void) => {
        if (res) {
          setUser((prevUserValue) => ({
            ...prevUserValue,
            ...res.user,
            isPaid: true,
            outstandingBalance: res.outstandingBalance,
          }));
          setPlan({
            deal: {
              chargeAmount: `${res.pricePerTransaction}`,
              ...res,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setIsAuth(false);
        } else {
          navigate(routes.DEADEND);
        }
      });
    }
  }, [isAuth, runDebtId])

  if(typeof isAuth === 'undefined') { 
    return <></>;
  }

  if(!isAuth) {
    return <Navigate to={getAfterLoginRoute(location, runDebtId)} />
  }

  return (
    <AuthContext.Provider value={''}>
      {children}
    </AuthContext.Provider>
  )
  
};

export default AuthContextProvider;
