import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import {
  GreenCircleCheckIcon,
  CloseIcon,
  Label,
  SMALL,
  TINY,
} from "@arborknot/design-system-v2";
import styles from "./toast.module.scss";

const transitionStyles = {
  entering: { opacity: 0, right: -500 },
  entered: { opacity: 1, right: 24 },
  exiting: { opacity: 0, right: -500 },
  exited: { opacity: 0, right: -500 },
  unmounted: { opacity: 0, right: -500 },
};

function Toast({ inAnimation }: { inAnimation: boolean }): JSX.Element {
  const { t } = useTranslation();
  const [localInAnimation, setLocalInAnimation] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (inAnimation) {
      setTimeout(() => {
        setLocalInAnimation(true);
      }, 570);
    } else {
      setLocalInAnimation(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [inAnimation]);

  return (
    <Transition in={localInAnimation} timeout={0}>
      {(state) => {
        return (
          <div
            className={styles.toast}
            style={{
              ...transitionStyles[state],
            }}
          >
            <GreenCircleCheckIcon />
            <div className={styles.texts}>
              <Label className={styles.label} size={SMALL}>
                {t("toast-success")}
              </Label>
              <Label className={styles.par} size={TINY}>
                {t("toast-updating-balance")}
              </Label>
            </div>
            <div className={styles.closeIcon}>
              <CloseIcon />
            </div>
          </div>
        );
      }}
    </Transition>
  );
}

export default Toast;
