import { useContext } from "react";
import UserContext from "../../../../context/UserContext";
import c from "classnames";
import styles from "./name.module.scss";
import { useTranslation } from "react-i18next";
import { BreakpointOverride } from "../../../../hooks/useBreakpointsHook";
import { Label, LARGE, MEDIUM } from "@arborknot/design-system-v2";

interface NameProps {
  className?: string;
}

function Name({ className }: NameProps) {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const nameBreakpoints: BreakpointOverride = {
    medium: LARGE,
    large: LARGE,
    extralarge: LARGE,
  };

  return (
    <div
      className={c(
        className,
        styles.nameContainer,
        "label-large",
        "label-bold"
      )}
    >
      <Label size={MEDIUM} breakpoints={nameBreakpoints} isBold>
        👋&nbsp;{t("navbar-hi")},&nbsp;{user?.firstName}!
      </Label>
    </div>
  );
}

export default Name;
