import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingPage } from "../components/LoadingPage/LoadingPage";
import { useFullstoryIdentify } from "../hooks/useFullstoryIdentify";
import usePortalConfig from "../hooks/usePortalConfig";
import ConfigContext, { Config } from "./ConfigContext";
import { enteredPortal } from "src/api/requests";
import { initI18n } from "../i18n";

const ConfigContextProvider = ({
                                 children,
                                 configContextOverride
                               }: {
  children: JSX.Element;
  configContextOverride?: Config;
}): JSX.Element => {
  const { runDebtId } = useParams();
  const navigate = useNavigate();
  const { config, isLoading } = usePortalConfig(navigate, runDebtId!);
  useFullstoryIdentify(runDebtId!);

  useEffect(() => {
    if (config?.dcaId) {
      const initTranslations = async () => await initI18n();
      window.sessionStorage.setItem("dcaId", config.dcaId);
      initTranslations().then(l => l);
    }
  }, [config?.dcaId]);

  useEffect(() => {
    if (runDebtId) {
      enteredPortal(runDebtId).then();
    }
  }, [runDebtId]);

  return (
    <LoadingPage isLoading={ isLoading }>
      <ConfigContext.Provider
        value={
          configContextOverride !== undefined
            ? configContextOverride
            : { ...(config as unknown as Config) }
        }
      >
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            sizes="24x24"
            href={ config?.logo ? `data:image/png;base64,${ config?.logo }}` : "" }
          />
        </Helmet>
        { children }
      </ConfigContext.Provider>
    </LoadingPage>
  );
};

export default ConfigContextProvider;
