import { createContext, SetStateAction } from "react";
import { IPlan, UserContextData } from "../types/UserContextData";
import { User } from "../types/User";
import { IDeal } from "../types/Deal";
import { OfferInterval, OfferOptionType } from "@arborknot/system-enums";

export const mockData: UserContextData = {
  setUser(user: User | SetStateAction<User>) {},
  setPlan(user) {
    console.log("setPlan");
  },
  setDeals() {
    console.log("setDeals");
  },
  setCanPay() {
    console.log("setDeals");
  },
  user: {
    firstName: "Avi",
    lastName: "Ron",
    outstandingBalance: 1000,
    currencyCode: "AUD",
  },
  deals: [
    {
      id: "1",
      totalPriceAfterDiscount: 1000,
      numberOfPayments: 2,
      totalDiscountAmount: 2239,
      type: OfferOptionType.Regular,
      offerGroupId: "",
      interval: OfferInterval.month,
      pricePerTransaction: 123,
      chargeAmount: "1000",
      isDefault: false,
    },
    {
      id: "2",
      totalPriceAfterDiscount: 313,
      totalDiscountAmount: 10,
      numberOfPayments: 24,
      offerGroupId: "",
      type: OfferOptionType.Regular,
      interval: OfferInterval.month,
      chargeAmount: "10",
      pricePerTransaction: 123,
      isDefault: false,
    },
    {
      id: "3",
      totalPriceAfterDiscount: 626,
      totalDiscountAmount: 200,
      numberOfPayments: 24,
      type: OfferOptionType.Regular,
      interval: OfferInterval.month,
      pricePerTransaction: 123,
      chargeAmount: "100",
      offerGroupId: "",
      isDefault: false,
    },
    {
      id: "4",
      totalPriceAfterDiscount: 626,
      totalDiscountAmount: 0,
      numberOfPayments: 1,
      type: OfferOptionType.Custom,
      offerGroupId: "",
      interval: OfferInterval.month,
      pricePerTransaction: 123,
      chargeAmount: "100",
      isDefault: false,
    },
    {
      id: "5",
      totalPriceAfterDiscount: 123456,
      totalDiscountAmount: 0,
      numberOfPayments: 5,
      offerGroupId: "",
      type: OfferOptionType.Custom,
      interval: OfferInterval.month,
      chargeAmount: "123456",
      pricePerTransaction: 123,
      isDefault: false,
    },
    {
      id: "6",
      totalPriceAfterDiscount: 626,
      totalDiscountAmount: 0,
      offerGroupId: "",
      numberOfPayments: 10,
      type: OfferOptionType.Custom,
      interval: OfferInterval.month,
      pricePerTransaction: 123,
      chargeAmount: "100",
      isDefault: false,
    },
    {
      id: "4",
      totalPriceAfterDiscount: 626,
      totalDiscountAmount: 0,
      numberOfPayments: 1,
      type: OfferOptionType.Custom,
      offerGroupId: "",
      interval: OfferInterval.month,
      pricePerTransaction: 123,
      chargeAmount: "100",
      isDefault: false,
    },
    {
      id: "5",
      totalPriceAfterDiscount: 123456,
      totalDiscountAmount: 0,
      offerGroupId: "",
      numberOfPayments: 5,
      type: OfferOptionType.Custom,
      interval: OfferInterval.month,
      pricePerTransaction: 123,
      chargeAmount: "123",
      isDefault: false,
    },
    {
      id: "6",
      offerGroupId: "",
      totalPriceAfterDiscount: 626,
      totalDiscountAmount: 0,
      numberOfPayments: 10,
      type: OfferOptionType.Custom,
      interval: OfferInterval.month,
      pricePerTransaction: 123,
      chargeAmount: "100",
      isDefault: false,
    },
    {
      id: "4",
      offerGroupId: "",
      totalPriceAfterDiscount: 626,
      totalDiscountAmount: 0,
      numberOfPayments: 1,
      type: OfferOptionType.Custom,
      interval: OfferInterval.month,
      pricePerTransaction: 123,
      chargeAmount: "100",
      isDefault: false,
    },
    {
      id: "5",
      offerGroupId: "",
      totalPriceAfterDiscount: 123456,
      totalDiscountAmount: 0,
      numberOfPayments: 5,
      type: OfferOptionType.Custom,
      interval: OfferInterval.month,
      pricePerTransaction: 123,
      chargeAmount: "123",
      isDefault: true,
    },
  ],
};

const UserContext = createContext<UserContextData>({
  setUser(user: SetStateAction<User>): void {},
  setDeals(deals: Array<IDeal>): void {},
  setPlan(plan: IPlan): void {},
  setCanPay(canPay: boolean): void {},
});

export default UserContext;
