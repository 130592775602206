import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getDebtStatus } from "src/api/requests";
import { IPoiStatusData } from "src/types/IPoiStatusData";
import PoiStatus from "src/types/PoiStatus";
import { routes } from "src/types/routes";
import { User } from "src/types/User";
import { UserContextData } from "src/types/UserContextData";
import { LoadingPage } from "../components/LoadingPage/LoadingPage";
import { IDeal } from "../types/Deal";
import ConfigContext from "./ConfigContext";
import UserContext from "./UserContext";
import { isRedirectableRoute } from "src/utils/routes";

const UserContextProvider = ({
  children,
  userContextOverride,
}: {
  children: JSX.Element;
  userContextOverride?: UserContextData;
}): JSX.Element => {
  const location = useLocation();
  const { runDebtId } = useParams();
  const { debtId } = useContext(ConfigContext);
  const navigate = useNavigate();
  const [plan, setPlan] = useState({});
  const [deals, setDeals] = useState<IDeal[]>([]);
  const [user, setUser] = useState<User>({});
  const [canPay, setCanPay] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (runDebtId) {
      getDebtStatus(runDebtId)
        .then((res: IPoiStatusData | void) => {
          if (res) {
            if (isRedirectableRoute(location)) {
              navigate(location.pathname);
            } else {
              navigateBasedOnResStatus(res);
            }
          }
        })
        .catch(() => {
          setIsLoading(false);
          navigate(routes.DEADEND);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [debtId]);

  const navigateBasedOnResStatus = (res: IPoiStatusData) => {
    if (res.poiStatus == PoiStatus.Approved && res.isPaid) {
      navigate(`${routes.SUMMARY}/${runDebtId}`);
      setUser((prevUserValue) => ({
        ...prevUserValue,
        isPaid: true,
      }));
    } else if (res.poiStatus == PoiStatus.Approved) {
      if (res.isIncetivizedCampaign) {
        if (res.isPaidIncetivized && !res.shouldShowDeals) {
          navigate(`${routes.ACTIONS_SUCCESS}/${runDebtId}`);
        } else if (!res.isPaidIncetivized) {
          navigate(`${routes.ACTIONS}/${runDebtId}`);
        } else {
          navigate(`${routes.LANDING}/${runDebtId}`);
        }
      } else {
        navigate(`${routes.LANDING}/${runDebtId}`);
      }
    } else if (
      res.poiStatus == PoiStatus.Pending ||
      res.poiStatus == PoiStatus.Failed
    ) {
      if(!location.pathname.startsWith(routes.POI)) {
        navigate(`${routes.POI}/${runDebtId}`);
      }
    } else if (res.poiStatus == PoiStatus.Blocked) {
      navigate(`${routes.POI}/${runDebtId}/blocked`);
    } else {
      navigate(routes.DEADEND);
    }
  };

  const contextData = {
    setPlan,
    plan,
    user,
    setUser,
    deals,
    setDeals,
    canPay,
    setCanPay,
  };

  const overridedContext = !userContextOverride
    ? contextData
    : { ...contextData, ...userContextOverride };

  return (
    <LoadingPage isLoading={isLoading}>
      <UserContext.Provider value={overridedContext}>
        {children}
      </UserContext.Provider>
    </LoadingPage>
  );
};

export default UserContextProvider;
