import { CurrencyCode } from "@arborknot/system-enums";
import { CountryCode } from "src/types/location";

export const formatAmountWithCurrency = (amount?: number | string, currencyCode?: string): string => {
  if (amount == null) {
    return "";
  } else if (!currencyCode) {
    return `${amount}`;
  }

  switch (currencyCode?.toLocaleUpperCase()) {
    case CurrencyCode.USD:
    case CurrencyCode.AUD:
      return `$${amount}`;
    case CurrencyCode.EUR:
      return `${amount}€`;
    case CurrencyCode.NIS:
      return `${amount}₪`;
    default:
      throw new Error("Unknown Currency Code");
  }
};

export const getCurrencySymbolByCode = (currencyCode?: string): string => {
  switch (currencyCode?.toLocaleUpperCase()) {
    case CurrencyCode.USD:
    case CurrencyCode.AUD:
      return `$`;
    case CurrencyCode.EUR:
      return `€`;
    case CurrencyCode.NIS:
      return `₪`;
    default:
      return "";
  }
};

export const toPrice = (amount: number | string, countryCode?: string) => {
  const isGrouping = countryCode === CountryCode.US;

  const options = { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2, 
    useGrouping: isGrouping 
  }
  return toNumber(amount).toLocaleString('en-US', options)
}

export const toNumber = (amount: string | number) => {
  if(!amount) {
    return 0;
  }

  if(typeof amount === 'number') {
    return amount;
  }
  return Number(amount.replace(/,/g, ''));
}
