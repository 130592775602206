import { useEffect, useState } from "react";
import { NavigateFunction, useParams } from "react-router-dom";
import { getDynamicContentContext } from "src/api/requests";
import { routes } from "../types/routes";

const useDynamicContentContext = (
  navigate: NavigateFunction
): { isLoading: boolean; dynamicContentContext: any } => {
  const { runDebtId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dynamicContentContext, setDynamicContentContext] = useState<any>({});

  useEffect(() => {
    if (runDebtId) {
      setIsLoading(true);
      window.failureReason = "none";
      getDynamicContentContext(runDebtId!)
        .then((data) => {
          setDynamicContentContext(data);
        })
        .catch((e) => {
          window.failureReason = useDynamicContentContext.name;
          if (e.response.status !== 401) {
            navigate(routes.DEADEND);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [runDebtId]);

  return { isLoading, dynamicContentContext };
};

export default useDynamicContentContext;
