import {
  ErrorCircleIcon,
  Select,
  SelectOption,
  TINY,
} from "@arborknot/design-system-v2";
import { EventPortal } from "@arborknot/event_types";
import { RootEntities } from "@arborknot/system-enums";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDcaLanguages, updateDebtLanguage } from "src/api/requests";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import UserContext from "src/context/UserContext";
import useEvent from "src/hooks/useEvent";
import styles from ".././footer.module.scss";
import { useParams } from "react-router-dom";

const LOCAL_STORAGE_LANGUAGE_KEY = "selectedLng";

export const LanguageSelector: React.FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [dcaLanguages, setDcaLanguages] =
    useState<{ lang: string; isDefault: boolean }[]>();
  const isLoading = dcaLanguages === undefined;
  const { sendEventCallback } = useEvent();
  const dcaId = window.sessionStorage.getItem("dcaId") ?? RootEntities.Dca;
  const { user } = useContext(UserContext);
  const { runDebtId } = useParams();

  useEffect(() => {
    // get languages
    getDcaLanguages(dcaId)
      .then((res) => {
        setDcaLanguages(res);
      })
      .catch((err) => {
        console.error("couldn't load dca languages", err);
      });
  }, [dcaId]);

  useEffect(() => {
    if (user?.languageCode) {
      changeLanguage(user?.languageCode);
    }
  }, [user?.languageCode]);

  const options = useMemo(() => {
    return (
      dcaLanguages?.map((lang) => {
        const countryCode = lang.lang.split("-")[1].toLowerCase();

        return {
          id: lang.lang,
          text: t(`languages.${countryCode}`),
          value: {
            lngCode: lang.lang,
            flag: (
              <span className={`fi fi-${countryCode.toLowerCase()}`}></span>
            ),
          },
        };
      }) ?? []
    );
  }, [dcaLanguages, t]);

  const currentLng = useMemo(() => {
    return options.find((option) => option.id === i18n.language);
  }, [options, i18n.language]);

  const handleLanguageChange = async (option: SelectOption) => {
    const newLng = option.value.lngCode;

    sendEventCallback(EventPortal.LanguageSwitch, {
      fromLang: currentLng?.value.lngCode,
      toLang: newLng,
    });

    changeLanguage(newLng);

    if(runDebtId){
      await updateDebtLanguage(runDebtId, newLng)
    }

  };

  const changeLanguage = (newLng: string) => {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, newLng);
    i18n.changeLanguage(newLng);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && options.length === 0 && <ErrorCircleIcon />}
      {!isLoading && options.length > 0 && (
        <Select
          buttonProps={{
            type: "ghost",
            size: TINY,
            controlType: "iconLeft",
            LeftComponent: currentLng?.value.flag,
          }}
          isSearchable={false}
          popoverClassname={styles.lngSelect}
          onSelect={handleLanguageChange}
          options={options}
          selectedOptionId={currentLng?.id}
          dataTestId={"support-language"}
        />
      )}
    </>
  );
};
