import { Location } from "react-router-dom";
import { routes } from "src/types/routes";
import { getDecodedChannelParam } from "./utils";

export const getAfterLoginRoute = (location: Location, runDebtId?: string) => {
  const onLogin = `on-login=${location.pathname}`;
  if (!runDebtId) {
    return `${routes.POI}/?${onLogin}`;
  }
  return `${routes.POI}/${runDebtId}?${onLogin}`;
};

export const setRouteAfterLogin = (location: Location, path: string) => {
  const urlParams = new URLSearchParams(getDecodedChannelParam());
  const onLoginPath = urlParams.get("on-login");
  if (onLoginPath) {
    return onLoginPath;
  }
  return path;
};

export const isRedirectableRoute = (location: Location) => {
  return [routes.CHANGE_PAYMENT_METHOD, routes.DISCLOSURE].find((route) => location.pathname.startsWith(route));
};
