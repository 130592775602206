export interface IConsent {
  customerId: string;
  status?: ConsentStatus;
  page: string;
  userAgent: string;
  incentivizedActionId?: string;
}

export enum ConsentStatus {
  accepted = "be980154-ac90-431b-bd5c-eb59aac45533",
  rejected = "e115f08c-41cd-4490-a52b-ba8f37559662",
}
