import { useNavigate } from "react-router-dom";
import useDynamicContentContext from "src/hooks/useDynamicContent";
import { UserContextData } from "src/types/UserContextData";
import { LoadingPage } from "../components/LoadingPage/LoadingPage";
import DynamicContentContext from "./DynamicContentContext";

const DynamicContextProvider = ({
  children,
}: {
  children: JSX.Element;
  userContextOverride?: UserContextData;
}): JSX.Element => {
  const navigate = useNavigate();
  const { isLoading, dynamicContentContext } =
    useDynamicContentContext(navigate);

  return (
    <LoadingPage isLoading={isLoading}>
      <DynamicContentContext.Provider value={dynamicContentContext}>
        {children}
      </DynamicContentContext.Provider>
    </LoadingPage>
  );
};

export default DynamicContextProvider;
