export type LocationStateType = {
  changePaymentMethod?: boolean;
  paymeBankTransferUrl?: string;
};

export const CountryCode: Record<CountryCodeOption, string> = {
  US: 'US',
  SP: 'US',
  IL: 'IL',
};

export type CountryCodeOption = 'US' | 'SP' | 'IL';