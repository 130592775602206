import { Loading } from "@arborknot/design-system-v2";
import i18n from "i18next";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { getDecodedChannelParam } from "src/utils/utils";
import styles from "./loadingPage.module.scss";

const loadingTexts: Record<string,string> ={
  'en-us': 'Please wait...',
  'es-es': 'Espere por favor...',
}
type LoadingPageProps = {
  isLoading?: boolean;
} & PropsWithChildren;

export const LoadingPage: React.FC<LoadingPageProps> = ({
  children,
  isLoading,
}) => {
  const { t } = useTranslation();
  const getLoadingText = () => {
    let text = t("loading-message")
    const urlParams = new URLSearchParams(getDecodedChannelParam());

    // if not translated yet
    if (!i18n.exists('loading-message')) {
      if (urlParams.get('lng') && loadingTexts[urlParams.get('lng') as string]) {
        text = loadingTexts[urlParams.get('lng') as string]
      } else {
        text = loadingTexts['en-us']
      }
    }
    return text
  }


  return (
    <>
      {isLoading ? (
        <div className={styles.loadingPageContainer}>
          <Loading
            className={styles.loadingPageLoader}
            text={getLoadingText()}
          />
        </div>
      ) : (
        children
      )}
    </>
  );
};
