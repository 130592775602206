import Lottie from "lottie-react";
import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { AnimationData } from "src/components/AnimationData";
import { AnimationDataDesktop } from "src/components/AnimationDataDesktop";
import Toast from "src/components/toast/toast";
import { UserContextData } from "src/types/UserContextData";
import ConfigContext, { Config } from "../../context/ConfigContext";
import ConfigContextProvider from "../../context/ConfigContextProvider";
import UserContextProvider from "../../context/UserContextProvider";
import CookieConsent from "../cookiesConsent/CookieConsent";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import NavigationGuard from "../navigationGuard/NavigationGuard";
import styles from "./pageLayout.module.scss";

type PageLayoutProps = {
  navbar?: boolean;
  isSummary?: boolean;
  withConsent?: boolean;
  withLogo?: boolean;
  children?: React.ReactNode;
  disableWebchat?: boolean;
  configContextOverride?: Config;
  userContextOverride?: UserContextData;
};

const Logo = () => {
  const { logo } = useContext(ConfigContext);
  return (
    <div className={styles.logo}>
      <img src={`data:image/png;base64, ${logo}`} alt="logo" />
    </div>
  );
};

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  navbar = true,
  withLogo = true,
  withConsent = true,
  isSummary,
  disableWebchat = false,
  configContextOverride,
  userContextOverride,
}) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [inAnimation, setInAnimation] = useState(false);
  const animationData = window.localStorage.getItem("animationData");
  const parsedAnimationData = useMemo(() => {
    return animationData ? JSON.parse(animationData) : undefined;
  }, [animationData]);

  useEffect(() => {
    if (parsedAnimationData) {
      if (!showAnimation) {
        setShowAnimation(true);
      }
      setInAnimation(true);
    }
  }, [parsedAnimationData, showAnimation]);

  const data = useMemo(() => {
    return isMobile
      ? AnimationData(
          +parsedAnimationData?.amount,
          +parsedAnimationData?.discount,
          parsedAnimationData?.persona
        )
      : AnimationDataDesktop(
          +parsedAnimationData?.amount,
          +parsedAnimationData?.discount,
          parsedAnimationData?.persona
        );
  }, [parsedAnimationData]);

  return (
    <ConfigContextProvider>
      <UserContextProvider
        userContextOverride={{
          ...(userContextOverride as UserContextData),
          setShowAnimation,
        }}
      >
        <Suspense>
          <NavigationGuard>
            <div className={styles.page}>
              {navbar && !showAnimation && (
                <Navbar className={styles.navbar} isSummary={isSummary} />
              )}
              {showAnimation && (
                <Lottie
                  className={styles.navbarAnimation}
                  animationData={data}
                  loop={false}
                  renderer={"svg"}
                  onComplete={() => {
                    setShowAnimation(false);
                    setInAnimation(false);
                    window.localStorage.removeItem("animationData");
                  }}
                />
              )}
              <Toast inAnimation={inAnimation} />
              {withLogo && <Logo />}
              <div className={styles.content}>{children}</div>
              {withConsent && <CookieConsent />}
              <Footer
                className={styles.footer}
                disableWebchat={disableWebchat}
              />
            </div>
          </NavigationGuard>
        </Suspense>
      </UserContextProvider>
    </ConfigContextProvider>
  );
};

export default PageLayout;
