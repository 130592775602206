/**
 * prefix dcaLegalUrl with http:// if it doesn't have http:// or https://
 * @param url
 * @returns url with http:// or https:// prefix
 */
export function prefixUrlWithHttpIfMissing(url: string): string {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "http://" + url;
  }

  return url;
}


/**
 * related to https://arborknot.atlassian.net/browse/AKD-14206
 * handlebars send portal link with html encoded url
 * so the channel is sent like  chn&#x3D;3 instead of chn=3
 * and channel logic is not working properly due to that
 * 
 * created a function that patches this
 */
export function getDecodedChannelParam() {
  const params = encodeURI(window.location.href.split("?")[1]);

  if (!params) {
    return "";
  }

  const res = params.replace("&#x3D;", "=");

  return res;
}
