import { CloseIcon, Label, LARGE } from "@arborknot/design-system-v2";
import { useTranslation } from "react-i18next";
import styles from "./sideMenuHeader.module.scss";
interface SideMenuHeaderProps {
  className?: string;
  onCloseClick?: () => void;
}

function SideMenuHeader({ className, onCloseClick }: SideMenuHeaderProps) {
  const { t } = useTranslation();

  return (
    <>
      <Label size={LARGE} isBold={true}>
        {t("sidemenu-youraccount")}
      </Label>
      <div>
        <CloseIcon onClick={onCloseClick} className={styles.closeButton} />
      </div>
    </>
  );
}
export default SideMenuHeader;
