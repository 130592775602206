import { Channel, PortalChannelType } from "@arborknot/system-enums";
import { useEffect, useState } from "react";
import { NavigateFunction, useParams } from "react-router-dom";
import { getDcaConfig, getPortalConfig } from "../api/requests";
import { Config } from "../context/ConfigContext";
import { routes } from "../types/routes";
import { getDefaultDca } from "src/utils/dca";
import { getDecodedChannelParam } from "src/utils/utils";

type usePortalConfigReturn = { config: Config | undefined; isLoading: boolean };

const usePortalConfig = (navigate: NavigateFunction, id: string): usePortalConfigReturn => {
  const { runDebtId } = useParams();
  const urlParams = new URLSearchParams(getDecodedChannelParam());
  const [config, setConfig] = useState<Config>({} as Config);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const channelType = urlParams.get("chn") as string;
  const channelTypeNane = PortalChannelType[+channelType];

  useEffect(() => {
    const channelId = Channel[channelTypeNane as keyof typeof Channel];
    if (channelId) {
      setConfig({ channelId, ...config });
    }
  }, [channelTypeNane]);

  useEffect(() => {
    setIsLoading(true);
    if (runDebtId) {
      getConfig(getPortalConfig(runDebtId));
    } else {
      getConfig(getDcaConfig(getDefaultDca()));
    }
  }, [id]);

  const getConfig = (call: Promise<any>) => {
    call
      .then((res: Config) => {
        setConfig((prev) => ({ ...prev, ...res }));
      })
      .catch(() => {
        navigate(routes.DEADEND);
        window.failureReason = usePortalConfig.name;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { config, isLoading };
};

export default usePortalConfig;
