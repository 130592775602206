import { MenuIcon } from "@arborknot/design-system-v2";
import { EventPortal } from "@arborknot/event_types";
import c from "classnames";
import React, {
  useCallback, useState
} from "react";
import useEvent from "src/hooks/useEvent";
import Name from "./components/Name/Name";
import Outstanding from "./components/Outstanding/Outstanding";
import SideMenu from "./components/SideMenu/SideMenu";
import styles from "./navbar.module.scss";

type NavbarProps = {
  className?: string;
  isSummary?: boolean;
};

const Navbar: React.FC<NavbarProps> = ({ className, isSummary }) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const { sendEventCallback } = useEvent();

  const handleMenuClick = useCallback(() => {
    sendEventCallback(EventPortal.OpenMenu);
    setSideMenuOpen(true);
  }, [sendEventCallback]);

  const handleMenuCloseClick = useCallback(() => setSideMenuOpen(false), []);

  return (
    <div className={c(styles.navbarContainer, className)}>
      <Name />
      <Outstanding
        className={styles.outstandingSection}
        isSummary={isSummary}
      />
      <div className={styles.menuSection} data-testid={"menu-icon"}>
        <MenuIcon onClick={handleMenuClick} />
      </div>
      {sideMenuOpen && <SideMenu onCloseClick={handleMenuCloseClick} />}
    </div>
  );
};

export default Navbar;
