import { useTranslation } from 'react-i18next';
import styles from './BannerIA.module.scss';
import { Button, Label, MEDIUM } from "@arborknot/design-system-v2"
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'src/types/routes';
import { getNextIncentivizedAction } from 'src/api/requests';
import useEvent from 'src/hooks/useEvent';
import { EventPortal } from '@arborknot/event_types';

interface IProps {
    target: 'main_banner' | 'menu_banner'
}

export const BannerIA = ({ target }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [next, setNext] = useState<any>();
    const { runDebtId } = useParams();
    const { sendEventCallback } = useEvent();

    useEffect(() => {
        if (runDebtId) {
            getNextIncentivizedAction(runDebtId)
                .then((resp: any) => {
                    if(!resp?.data?.isIncetivizedCampaign) {
                        setNext(resp.data?.incentivizedAction);
                    }
                })

        }
    }, [runDebtId])

    if (!next) {
        return <></>
    }


    const {
        header_banner: headerBanner,
        sub_title_banner: subTitleBanner,
        cta_text_banner: ctaTextBanner
    } = next;

    const handleClaim = () => {
       sendEventCallback(EventPortal.IABannerClick, { 
            incentivizedActionId: next.id, 
            targetElement: target
        });
        navigate(`${routes.ACTIONS}/${runDebtId}`);
    }

    return (
        <div className={styles.container}>
            <div className={styles.info}>
                <Label size="large" isBold>{t(headerBanner, { ns: "content-ia-header-banner" })}</Label>
                <Label size="medium" isBold className={styles.mark} >{t(subTitleBanner, { ns: "content-ia-sub-title-banner" })}</Label>
            </div>
            <Button
                className={styles.button}
                size={MEDIUM}
                controlType="textOnly"
                type={"primary"}
                onPress={handleClaim}
                text={t(ctaTextBanner, { ns: "content-ia-ctatext-banner" })}
                dataTestId={"banner-claim"}
            />
        </div>
    )
}